$(function() {

	function startAnimate(){
		$("#sec1-logo").delay(1500).animate({ opacity: 1 },2000);
	}

	$("body").queryLoader2({
		onComplete: startAnimate(),
		barColor: "blue",
		backgroundColor: "white",
		barHeight: 2,
	});


});
